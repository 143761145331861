// Lib required for class-transformer lib
// WARNING: this import should be at the top of this file, otherwise app broken
import 'reflect-metadata';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import { sentryInit } from '../sentry/sentry-init';
import { intercomInit } from '@integration/intercom/intercom-init';
import { gtmInit } from '@integration/analytics/google-tag-manager/gtm-init';
import { rewardfulInit } from '@integration/affiliate/rewardful-init';
import { facebookInit } from '@integration/social-login/facebook-init';
import { stripeInit } from '@integration/stripe/stripe-init';
import { googleInit } from '@integration/social-login/google-init';

// Run Google tag manager
gtmInit();

rewardfulInit();

// Run Sentry
sentryInit();

// Run Intercom
intercomInit();

// Run facebook init
facebookInit();

// Run google init
googleInit();

// Run Stripe
stripeInit();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

if (environment.production) {
  enableProdMode();
}
