import { Component } from '@angular/core';

/**
 * Wrapper component for toggle buttons. Positioning toggle button
 */
@Component({
  selector: 'app-toggler-group',
  templateUrl: './toggler-group.component.html',
  styleUrls: ['./toggler-group.component.scss']
})
export class TogglerGroupComponent {
}
