import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryAutocompleteComponent } from './country-autocomplete.component';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '@shared/directives/directives.module';
import { ValidationErrorModule } from '@shared/component';

@NgModule({
  declarations: [
    CountryAutocompleteComponent
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    DirectivesModule,
    ValidationErrorModule
  ],
  exports: [
    CountryAutocompleteComponent
  ]
})
export class CountryAutocompleteModule { }
