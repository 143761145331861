import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCard'
})
export class CreditCardPipe implements PipeTransform {
  transform(value: string, type: string, canEdit: boolean): string {
    if (value && value.trim() && !canEdit) {
      if (type === 'number') {
        return value.replace(/\d{4}(?= \d{4})/g, '****');
      } if (type === 'cvv') {
        return '***';
      }
    }
    return value;
  }
}
