import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from './progress-bar.component';
import { PipeModule } from '@shared/pipe/pipe.module';

@NgModule({
  declarations: [ProgressBarComponent],
  exports: [
    ProgressBarComponent
  ],
  imports: [
    CommonModule,
    PipeModule
  ]
})
export class ProgressBarModule { }
