import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe for sort and array of objects by passed field and direction
 */
@Pipe({
  name: 'sortBy'
})
export class SortByPipe<T extends object> implements PipeTransform {

  transform(array: T[], field: string, direction: 'asc' | 'desc' = 'asc'): T[] {
    // @TODO Alex: make this pipe work with collections and dictionaries
    const isAsc: boolean = direction === 'asc';

    array.sort((a: T, b: T): number => {
      switch (true) {
        case a[field] < b[field]:
          return isAsc ? 1 : -1;
        case a[field] > b[field]:
          return isAsc ? -1 : 1;
        default:
          return 0;
      }
    });

    return array;
  }

}
