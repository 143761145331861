import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataCardComponent } from './data-card.component';
import { DataCardGroupComponent } from '@shared/component/data-card/data-card-group/data-card-group.component';
import { DirectivesModule } from '@shared/directives/directives.module';

@NgModule({
  declarations: [
    DataCardComponent,
    DataCardGroupComponent
  ],
  imports: [
    CommonModule,
    DirectivesModule
  ],
  exports: [
    DataCardComponent,
    DataCardGroupComponent
  ]
})
export class DataCardModule { }
