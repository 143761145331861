import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentComponent } from '@shared/component/document/document.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { PipeModule } from '@shared/pipe/pipe.module';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [
    DocumentComponent
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    PipeModule,
    MatCardModule
  ],
  exports: [
    DocumentComponent
  ]
})
export class DocumentModule { }
