import { Component, ViewEncapsulation } from '@angular/core';
import { ConfirmPopup } from 'primeng/confirmpopup';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('animation', [
      state(
        'void',
        style({
          transform: 'scaleY(0.8)',
          opacity: 0
        })
      ),
      state(
        'open',
        style({
          transform: 'translateY(0)',
          opacity: 1
        })
      ),
      transition('void => open', animate('{{showTransitionParams}}')),
      transition('open => void', animate('{{hideTransitionParams}}'))
    ])
  ],
  host: {
    class: 'p-element'
  }
})
export class ConfirmPopupComponent extends ConfirmPopup {
}
