import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePopupComponent } from '@shared/component/image-popup/image-popup.component';
import { MaterialModule } from '@shared/material/material.module';

@NgModule({
  declarations: [
    ImagePopupComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    ImagePopupComponent
  ]
})
export class ImagePopupModule { }
