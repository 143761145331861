import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionBannerOnboardingUserComponent } from './subscription-banner-onboarding-user/subscription-banner-onboarding-user.component';
import { SubscriptionBannerOnboardingEmployeeComponent } from '@components/subscription-banner/subscription-banner-onboarding/subscription-banner-onboarding-employee/subscription-banner-onboarding-employee.component';
import { SubscriptionModule } from '@client/shared/components/subscription/subscription.module';
import { MatDialogModule } from '@angular/material/dialog';
import { DirectivesModule } from '@shared/directives/directives.module';
import { SubscriptionBannerOnboardingComponent } from './subscription-banner-onboarding.component';

/**
 * @TODO app/shared is a wrong place for components like onboarding-employee/onboarding-user
 */
@NgModule({
  declarations: [
    SubscriptionBannerOnboardingUserComponent,
    SubscriptionBannerOnboardingEmployeeComponent,
    SubscriptionBannerOnboardingComponent
  ],
  imports: [
    CommonModule,
    SubscriptionModule,
    MatDialogModule,
    DirectivesModule
  ]
})
export class SubscriptionBannerOnboardingModule { }
