import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customSlice'
})
export class SlicePipe implements PipeTransform {
  transform(value: string, length: number): string {
    if (value) {
      if (value.length > length) {
        return value.slice(0, length).concat('...');
      } 
      return value;
            
    }
    return '-';
  }
}
