import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OccupationAutocompleteComponent } from './occupation-autocomplete.component';
import { MaterialModule } from '@shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PreloaderModule, ValidationErrorModule } from '@shared/component';
import { DirectivesModule } from '@shared/directives/directives.module';

@NgModule({
  declarations: [
    OccupationAutocompleteComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrorModule,
    DirectivesModule,
    PreloaderModule
  ],
  exports: [
    OccupationAutocompleteComponent
  ]
})
export class OccupationAutocompleteModule { }
