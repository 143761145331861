import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { JwtService, User } from 'taxtank-core';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard {
  constructor(private router: Router, private jwtService: JwtService) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.check(state);
  }
  check(state: RouterStateSnapshot): boolean {
    // not logged in, redirect to login page
    if (!this.jwtService.getToken()) {
      this.router.navigate(['/login'], { fragment: state.url });
    }

    // set user email to sentry for better user identifying
    const user: User = this.jwtService.getUser();
    Sentry.setUser({ email: user.email });

    return true;
  }
}
