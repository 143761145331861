import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { environment } from '@env/environment';
import { AuthService } from 'taxtank-core';

const TIMEOUT = 30;

@Component({
  selector: 'app-idle-logout',
  templateUrl: './idle-logout.component.html',
  styleUrls: ['./idle-logout.component.scss']
})
export class IdleLogoutComponent implements OnInit {
  countdown = 0;

  constructor(
    private idle: Idle,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.isLoggedInSubject.subscribe((isLoggedIn: boolean) => {
      if (!isLoggedIn) {
        return;
      }

      this.setUp();

      this.idle.onIdleEnd.subscribe(() => {
        this.countdown = 0;
        this.changeDetectorRef.detectChanges();
      });
      this.idle.onTimeout.subscribe(() => {
        this.authService.logoutFront();
      });

      this.idle.onTimeoutWarning.subscribe((countdown) => this.countdown = countdown);
      this.idle.watch();
    });
  }

  setUp(): void {
    this.idle.setIdle(environment.logoutTimeout);
    this.idle.setTimeout(TIMEOUT);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }
}
