import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClaimPercentInputComponent } from './claim-percent-input.component';
import { ValidationErrorModule } from '@shared/component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/material/material.module';

@NgModule({
  declarations: [
    ClaimPercentInputComponent
  ],
  imports: [
    CommonModule,
    ValidationErrorModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  exports: [
    ClaimPercentInputComponent
  ]
})
export class ClaimPercentInputModule { }
