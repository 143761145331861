import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { AnonymGuard, AuthGuard } from '@shared/guards';

const route: Routes = [
  {
    path: 'client',
    canActivate: mapToCanActivate([AuthGuard]),
    loadChildren: () => import('@client/client.module').then(m => m.ClientModule)
  },
  {
    path: 'firm',
    canActivate: mapToCanActivate([AuthGuard]),
    loadChildren: () => import('@firm/firm.module').then(m => m.FirmModule)
  },
  {
    path: 'demo',
    loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule)
  },
  {
    path: '',
    canActivate: mapToCanActivate([AnonymGuard]),
    loadChildren: () => import('@anonym/anonym.module').then(m => m.AnonymModule)
  },
  {
    path: '**',
    loadChildren: () => import('./user/not-found/not-found.module').then(m => m.NotFoundModule)
  }
];

@NgModule({
  imports: [
    // @TODO vik check
    RouterModule.forRoot(route, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class RoutingModule {}
