import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ImageComponent } from './image.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { PipeModule } from '@shared/pipe/pipe.module';

@NgModule({
  declarations: [
    ImageComponent
  ],
  exports: [
    ImageComponent
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    PipeModule,
    NgOptimizedImage
  ]
})
export class ImageModule { }
