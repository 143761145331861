import { NgModule } from '@angular/core';
import { HeaderComponent } from '@shared/component/layout/header/header.component';
import { TitleModule } from '@shared/component/layout/header/title/title.module';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SidenavToggleButtonModule } from '@shared/component/layout/sidenav-toggle-button/sidenav-toggle-button.module';
import { NotificationModule } from 'src/app/user/notification/notification.module';
import { YearChooserModule } from '@client/shared/components/year-chooser/year-chooser.module';
import { HeaderMenuModule } from '@shared/component/layout/header/header-menu/header-menu.module';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    TitleModule,
    MatToolbarModule,
    MatTooltipModule,
    NotificationModule,
    SidenavToggleButtonModule,
    YearChooserModule,
    HeaderMenuModule,
    RouterModule
  ],
  exports: [
    HeaderComponent,
    TitleModule
  ]
})
export class HeaderModule { }
