import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ServicePriceListEnum,
  ServiceProductCollection, ServiceSubscription, ServiceSubscriptionItem, SubscriptionService
} from 'taxtank-core';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { BaseComponent } from '@shared/component/base/base.component';

@Component({
  selector: 'app-trial-subscription',
  templateUrl: './trial-subscription.component.html',
  styleUrls: ['./trial-subscription.component.scss']
})
export class TrialSubscriptionComponent extends BaseComponent {
  products: ServiceProductCollection;
  priceList = ServicePriceListEnum;
  selectedPrices: number[] = [];

  constructor(
    private dialogRef: MatDialogRef<TrialSubscriptionComponent>,
    private router: Router,
    private subscriptionService: SubscriptionService,
  ) {
    super();
  }

  toggle(priceId: number, isSelected: boolean) {
    isSelected ? this.selectedPrices.push(priceId) : this.selectedPrices.splice(this.selectedPrices.indexOf(priceId), 1);
  }

  submit(): void {
    const subscription: ServiceSubscription = plainToClass(ServiceSubscription, {
      items: this.selectedPrices.map(price => plainToClass(ServiceSubscriptionItem, { price: { 'id': price } }))
    });

    this.subscriptionService.startTrial(subscription).subscribe(() => {
      // @TODO vik refresh token? since roles has changed
      this.dialogRef.close();
      this.router.navigateByUrl('/client');
    });
  }
}
