import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavToggleButtonComponent } from '@shared/component/layout/sidenav-toggle-button/sidenav-toggle-button.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    SidenavToggleButtonComponent
  ],
  imports: [
    CommonModule,
    MatTooltipModule
  ],
  exports: [
    SidenavToggleButtonComponent
  ]
})
export class SidenavToggleButtonModule { }
