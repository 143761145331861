import { NgModule } from '@angular/core';
import { HeaderMenuComponent } from '@shared/component/layout/header/header-menu/header-menu.component';
import { ImageModule } from '@shared/component/image/image.module';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@shared/material/material.module';
import { VideoGalleryDialogModule } from '@shared/component/video-gallery-dialog/video-gallery-dialog.module';
import { PipeModule } from '@shared/pipe/pipe.module';
import { DirectivesModule } from '@shared/directives/directives.module';

@NgModule({
  declarations: [
    HeaderMenuComponent
  ],
  imports: [
    ImageModule,
    CommonModule,
    MatTooltipModule,
    RouterModule,
    MaterialModule,
    VideoGalleryDialogModule,
    PipeModule,
    DirectivesModule
  ],
  exports: [
    HeaderMenuComponent
  ]
})
export class HeaderMenuModule { }
