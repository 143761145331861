import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoGalleryDialogComponent } from '@shared/component/video-gallery-dialog/video-gallery-dialog.component';
import { VideoGalleryComponent } from '@shared/component/video-gallery-dialog/video-gallery/video-gallery.component';
import { PreloaderModule } from '../preloader/preloader.module';
import { MaterialModule } from '@shared/material/material.module';
import { FormsModule } from '@angular/forms';
import { PipeModule } from '@shared/pipe/pipe.module';



@NgModule({
  declarations: [
    VideoGalleryDialogComponent,
    VideoGalleryComponent
  ],
  imports: [
    CommonModule,
    PreloaderModule,
    MaterialModule,
    FormsModule,
    PipeModule
  ],
  exports: [
    CommonModule,
    VideoGalleryDialogComponent
  ]
})
export class VideoGalleryDialogModule { }
