/**
 * @TODO uncomment when google sign in will be done
 */

import { environment } from '@env/environment';

/**
 * Run google initialization
 */
export function googleInit() {
  if (!environment['googleClientId']) {
    return;
  }

  const script = document.createElement('script');
  const scriptRef = document.getElementsByTagName('script')[1];

  script.async = true;
  script.defer = true;
  script.src = 'https://accounts.google.com/gsi/client';
  scriptRef.parentNode.insertBefore(script, scriptRef);
}
