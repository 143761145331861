<mat-form-field>
  <mat-label>{{ label }}</mat-label>

  <input matInput
         [matAutocomplete]="autocomplete"
         [errorStateMatcher]="errorStateMatcher"
         [formControl]="ngControl.control"
         [placeholder]="placeholder"
         (input)="onSearch.next($event.target.value)"
         (blur)="onTouched()"/>

  <div matSuffix>
    <ng-container [appPreloader]="[endpoints.PROPERTIES_SUGGESTIONS_GET, endpoints.CORELOGIC_TOKEN_GET]"
                  [preloaderType]="preloaderTypes.ICON"
                  [preloaderSize]="20">
    </ng-container>
  </div>

  <mat-autocomplete #autocomplete="matAutocomplete" requireSelection (optionSelected)="selectSuggestion($event.option.value)">
    <ng-container *ngIf="(suggestions$ | async) as suggestions">
      <mat-option *ngFor="let suggestion of suggestions" [value]="suggestion">
        {{suggestion.suggestion}}
      </mat-option>
    </ng-container>
  </mat-autocomplete>

  <mat-error>
    <app-validation-error [errors]="ngControl.errors"></app-validation-error>
  </mat-error>
</mat-form-field>
