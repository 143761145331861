import {
  ChangeDetectorRef,
  ComponentFactory,
  ComponentFactoryResolver,
  Directive,
  Input,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { PreloaderComponent } from '@shared/component/preloader/preloader.component';
import { PreloaderTypeEnum } from '@shared/component/preloader/preloader-type.enum';
import { Endpoint, PreloaderService } from 'taxtank-core';

/**
 * @TODO ALex full refactoring, huge performance issues
 * Directive append preloader to elements when passed endpoints are loading
 */
@Directive({
  selector: '[appPreloader]'
})
export class PreloaderDirective implements OnInit {
  // list of endpoints
  @Input() appPreloader: Endpoint[];
  // preloader component type
  // @TODO define preloader type automatically depended of endpoint method
  @Input() preloaderType: PreloaderTypeEnum = PreloaderTypeEnum.SECTION;
  @Input() preloaderSize = 40;

  constructor(
    private preloaderService: PreloaderService,
    private factoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.preloaderService.get().subscribe((activeEndpoints: Endpoint[]): void => {
      const counter = this.appPreloader.filter((endpoint) => activeEndpoints.includes(endpoint)).length;

      // add preloader only if preloader is not added yet
      if (counter && !this.viewContainerRef.get(0)) {
        this.showPreloader();
        // hide preloader only if no more active preloaders
      } else if (!counter && this.viewContainerRef.get(0)) {
        // a little timeout required to update view before stopping preloader
        setTimeout(() => this.hidePreloader(), 100);
      }
    });
  }

  /**
   * Append preloader component to directive view
   */
  showPreloader(): void {
    // get component factory
    const factory: ComponentFactory<PreloaderComponent> = this.factoryResolver.resolveComponentFactory(PreloaderComponent);
    // create form component instance
    const preloaderInstance: PreloaderComponent = this.viewContainerRef.createComponent(factory, 0).instance;
    preloaderInstance.type = this.preloaderType;
    preloaderInstance.size = this.preloaderSize;
    // detect changes after preloader component view was checked
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Remove preloader component from directive view
   */
  hidePreloader(): void {
    this.viewContainerRef.clear();
    // detect changes after preloader component view was removed
    this.changeDetectorRef.markForCheck();
  }
}
