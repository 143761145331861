import { ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DirectivesModule } from '@shared/directives/directives.module';
import { ValidationErrorModule } from '@shared/component';
import { PipeModule } from '@shared/pipe/pipe.module';
import { AbstractControlComponent } from '@shared/component/form-controls/abstract-control/abstract-control.component';
import { CorelogicService, CorelogicSuggestion } from 'taxtank-core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-location-autocomplete',
  templateUrl: './location-autocomplete.component.html',
  styleUrls: ['./location-autocomplete.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    DirectivesModule,
    ValidationErrorModule,
    PipeModule,
  ]
})
export class LocationAutocompleteComponent extends AbstractControlComponent<string> implements OnInit {
  suggestions$: Observable<CorelogicSuggestion[]>;
  minSearchLength = 3;
  /**
   * subject to prevent to many backend searches (delay/distinct/filter/etc)
   * required because valueChanges are not emitted until option selected with requireSelection directive
   */
  searchDelay = 500;
  onSearch: EventEmitter<string> = new EventEmitter();

  constructor(
    public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef,
    private corelogicService: CorelogicService
  ) {
    super(ngControl, changeDetectorRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.onSearch.pipe(
      debounceTime(this.searchDelay),
      distinctUntilChanged(),
      map((value: string) => {
        // no need to search when value is not actually search string
        if (!value) {
          return '';
        }

        // trim to avoid spaces in searchQuery, we should not send request started or finished with spaces
        // uppercase to make search string similar to corelogic format
        return value.trim();
      }),
      // do nothing when query is too short
      filter((searchQuery: string) => searchQuery.length >= this.minSearchLength)
    ).subscribe((searchQuery: string) => {
      this.suggestions$ = this.corelogicService.getSuggestions(searchQuery);
    });
  }

  selectSuggestion(suggestion: CorelogicSuggestion): void {
    this.ngControl.control.setValue(suggestion.suggestion, { emitEvent: false });
  }
}
