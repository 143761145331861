import { Pipe, PipeTransform } from '@angular/core';

// @Todo add more cases for pipe transform value, if it will be needed
/**
 * Transform empty values, like null, empty string into 'N/A' string
 */
@Pipe({
  name: 'na'
})
export class NAPipe implements PipeTransform {

  transform(value: string | number, placeholder = 'N/A'): string | number {
    return value ? value : placeholder;
  }
}
