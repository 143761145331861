import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneInputComponent } from './phone-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountryAutocompleteModule } from '../../autocomplete/country-autocomplete/country-autocomplete.module';
import { ValidationErrorModule } from '@shared/component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    PhoneInputComponent
  ],
  exports: [
    PhoneInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CountryAutocompleteModule,
    ValidationErrorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule
  ]
})
export class PhoneInputModule { }
