import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {

  constructor(
    private currencyPipe: CurrencyPipe,
  ) {
  }

  transform(number: any, withoutFraction = false): any {
    // we don't need to remove the fractions for amounts greater than a million
    let isMoreThanMillion = false;

    let abs: number = Math.abs(number);
    const rounder: number = Math.pow(10, 1);
    let key = '';
    const formats: any = [
      {key: 'B', value: Math.pow(10, 9)},
      {key: 'M', value: Math.pow(10, 6)}
    ];

    for (let i = 0; i < formats.length; i++) {
      let reduced: number = abs / formats[i].value;
      reduced = (reduced * rounder) / rounder;
      if (reduced >= 1) {
        abs = reduced;
        key = formats[i].key;

        isMoreThanMillion = true;
        break;
      }
    }

    if (number < 0) {
      return `(${this.currencyPipe.transform(abs, 'USD', 'symbol', !isMoreThanMillion && withoutFraction ? '1.0-0' : '')}${key})`;
    } 
    return `${this.currencyPipe.transform(abs, 'USD', 'symbol', !isMoreThanMillion && withoutFraction ? '1.0-0' : '')}${key}`;
    
  }
}
