import { NgModule } from '@angular/core';
import { TermsPrivacyDialogComponent } from '@components/terms/terms-privacy-dialog/terms-privacy-dialog.component';
import { MaterialModule } from '@shared/material/material.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [
    TermsPrivacyDialogComponent,
  ],
  imports: [
    MaterialModule,
    PdfViewerModule
  ],
  exports: [
    TermsPrivacyDialogComponent
  ]
})
export class TermsModule { }
