import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragNDropComponent } from './drag-n-drop.component';
import { FormsModule } from '@angular/forms';
import { PipeModule } from '@shared/pipe/pipe.module';
import { MaterialModule } from '@shared/material/material.module';
import { ValidationErrorModule } from '@shared/component';
import {DirectivesModule} from '@shared/directives/directives.module';


@NgModule({
  declarations: [
    DragNDropComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipeModule,
    MaterialModule,
    ValidationErrorModule,
    DirectivesModule
  ],
  exports: [
    DragNDropComponent
  ]
})
export class DragNDropModule { }
