import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractModel, Collection, CollectionDictionary } from 'taxtank-core';

/**
 * Pipe returns collection by key from dictionary. Works with async data
 */
@Pipe({
  name: 'dictionaryAsync'
})
export class DictionaryAsyncPipe implements PipeTransform {
  transform(dictionary$: Observable<CollectionDictionary<Collection<AbstractModel>>>, key: string | number): Observable<Collection<AbstractModel>> {
    return dictionary$.pipe(
      map((dictionary: CollectionDictionary<Collection<AbstractModel>>): Collection<AbstractModel> => dictionary.get(key))
    );
  }
}
