import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploaderComponent } from './file-uploader.component';
import { FormsModule } from '@angular/forms';
import { PipeModule } from '@shared/pipe/pipe.module';
import { MaterialModule } from '@shared/material/material.module';
import { ValidationErrorModule } from '@shared/component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { PreloaderModule } from '../../../preloader/preloader.module';
import { FileUploadModule } from 'primeng/fileupload';



@NgModule({
    declarations: [
      FileUploaderComponent
    ],
    exports: [
      FileUploaderComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      PipeModule,
      MaterialModule,
      ValidationErrorModule,
      DirectivesModule,
      MatProgressBarModule,
      PreloaderModule,
      FileUploadModule
    ]
})
export class FileUploaderModule { }
