import { Pipe, PipeTransform } from '@angular/core';
import {KeyValue, KeyValuePipe} from '@angular/common';

/**
 * extended version of keyvalue pipe suitable for numeric enums ([key: string]: number)
 * created to avoid duplicated keys problem
 */
@Pipe({
  name: 'ekeyvalue'
})
export class EKeyValuePipe extends KeyValuePipe implements PipeTransform {
  transform<K, V>(input: null, compareFn?: (a: KeyValue<K, V>, b: KeyValue<K, V>) => number): null {
    const a: any = super.transform(input, compareFn);
    return a.slice(0, a.length / 2);
  }
}
