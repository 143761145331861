import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSizeConvert'
})
export class FileSizeConvertPipe implements PipeTransform {

  transform(value: number): string {
    const fSExt: string[] = ['Bytes', 'KB', 'MB'];
    let i = 0;
    while (value > 900) {
      value /= 1024;
      i++;
    }
    return `${(Math.round(value * 100) / 100)} ${fSExt[i]}`;
  }

}
