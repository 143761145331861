export const MATERIAL_CONFIG: {[key: string]: {[key: string]: string | number | boolean | object}} = {
  snackBar: {
    duration: 5000,
    horizontalPosition: 'center',
    verticalPosition: 'top',
    panelClass: 'info'
  },
  progressSpinner: {
    diameter: 20
  },
  expansionPanel: {
    hideToggle: false,
    expandedHeight: 'auto',
    collapsedHeight: 'auto',
  },
  dialog: {
    autoFocus: false,
    disableClose: true,
    hasBackdrop: true,
    panelClass: ['dialog-medium']
  },
  label: {
    floatLabel: 'always'
  },
  dateFormat: {
    parse: {
      dateInput: 'DD.MM.YYYY'
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'DD/MM/YYYY',
      monthYearA11yLabel: 'MMMM YYYY'
    }
  },
  paginator: {
    pageSizeOptions: [5, 10, 25, 50, 100],
    pageSize: 50,
    showFirstLastButtons: true
  },
  ripple: {
    disabled: true
  }
};
