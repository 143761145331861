import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TankTypeSelectComponent
} from '@shared/component/form-controls/select/tank-type-select/tank-type-select.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidationErrorModule } from '@shared/component';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [TankTypeSelectComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    FormsModule,
    ValidationErrorModule,
    MatRadioModule,
    MatTooltipModule,
    ReactiveFormsModule
  ],
  exports: [
    TankTypeSelectComponent
  ]
})
export class TankTypeSelectModule { }
