import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessSelectComponent } from './business-select.component';
import { MaterialModule } from '@shared/material/material.module';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '@shared/directives/directives.module';
import { PreloaderModule, ValidationErrorModule } from '@shared/component';



@NgModule({
  declarations: [
    BusinessSelectComponent
  ],
  exports: [
    BusinessSelectComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    DirectivesModule,
    ValidationErrorModule,
    PreloaderModule
  ]
})
export class BusinessSelectModule { }
