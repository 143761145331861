import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidationErrorModule } from '@shared/component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { BusinessActivityAutocompleteComponent } from './business-activity-autocomplete.component';

@NgModule({
  declarations: [
    BusinessActivityAutocompleteComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrorModule,
    DirectivesModule
  ],
  exports: [
    BusinessActivityAutocompleteComponent
  ]
})
export class BusinessActivityAutocompleteModule { }
