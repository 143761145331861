import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'observable'
})
export class ObservablePipe implements PipeTransform {

  transform(value: unknown): Observable<unknown> {
    return of(value);
  }

}
