import { environment } from '@env/environment';

/**
 * Run Rewardful initialization to set up affiliate and referral programs with Stripe & Paddle.
 * https://developers.rewardful.com/javascript-api/overview#add-the-javascript-tag-+-snippet
 */
export function rewardfulInit() {
  if (!environment.rewardfulId) {
    return;
  }

  runExecutionScript();
  runExternalScript();
}

function runExecutionScript() {
  const script = document.createElement('script');
  // script will be placed as first child of <head> element
  const scriptRef = document.getElementsByTagName('script')[0];

  script.text = `(() => {
    window['_rwq'] = 'rewardful';
    window['rewardful'] = window['rewardful'] || function () {
      (window['rewardful'].q = window['rewardful'].q || []).push(arguments);
    };
  })();`;

  scriptRef.parentNode.insertBefore(script, scriptRef);
}

function runExternalScript() {
  const script = document.createElement('script');
  // insert will be placed after previous script (after launch runExecutionScript())
  const scriptRef = document.getElementsByTagName('script')[1];

  script.async = true;
  script.src = 'https://r.wdfl.co/rw.js';
  script.setAttribute('data-rewardful', environment.rewardfulId);

  scriptRef.parentNode.insertBefore(script, scriptRef);
}
