import { environment } from '@env/environment';

/**
 * Run Intercom initialization
 */
export function intercomInit() {
  // Do nothing when intercom app id is missing
  if (!environment.intercomAppId) {
    return;
  }

  window['intercomSettings'] = {
    app_id: environment.intercomAppId,
    custom_launcher_selector: '#intercom_support'
  };

  (() => {
    const w: Window = window;
    const ic = w['Intercom'];
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w['intercomSettings']);
    } else {
      const d = document;
      const i = () => {
        // eslint-disable-next-line prefer-rest-params
        i.c(arguments);
      };
      i.q = [];
      i.c = (args) => {
        i.q.push(args);
      };
      w['Intercom'] = i;
      const l = () => {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${environment.intercomAppId}`;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (w['attachEvent']) {
        w['attachEvent']('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
}
