import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComparisonComponent } from './comparison.component';
import { PipeModule } from '@shared/pipe/pipe.module';
import { ComparisonArrowComponent } from './comparison-arrow/comparison-arrow.component';

@NgModule({
  declarations: [
    ComparisonComponent,
    ComparisonArrowComponent
  ],
  imports: [
    CommonModule,
    PipeModule
  ],
  exports: [
    ComparisonComponent,
    ComparisonArrowComponent
  ]
})
export class ComparisonModule { }
