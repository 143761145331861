import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionBannerStartComponent } from './subscription-banner-start/subscription-banner-start.component';
import { SubscriptionBannerFeedbackComponent } from './subscription-banner-feedback/subscription-banner-feedback.component';
import { SubscriptionBannerUpgradeComponent } from './subscription-banner-upgrade/subscription-banner-upgrade.component';
import { SubscriptionModule } from '@client/shared/components/subscription/subscription.module';
import { MatDialogModule } from '@angular/material/dialog';
import { SubscriptionBannerUpgradePropertyComponent } from '@components/subscription-banner/subscription-banner-upgrade/subscription-banner-upgrade-property/subscription-banner-upgrade-property.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { SubscriptionBannerPaidComponent } from './subscription-banner-paid/subscription-banner-paid.component';
import { SubscriptionBannerOnboardingModule } from '@components/subscription-banner/subscription-banner-onboarding/subscription-banner-onboarding.module';
import { SubscriptionBannerTrialSoleComponent } from '@components/subscription-banner/subscription-banner-trial/subscription-banner-trial-sole/subscription-banner-trial-sole.component';
import { SubscriptionBannerTrialPropertyComponent } from '@components/subscription-banner/subscription-banner-trial/subscription-banner-trial-property/subscription-banner-trial-property.component';
import { SubscriptionBannerUpgradeSoleComponent } from '@components/subscription-banner/subscription-banner-upgrade/subscription-banner-upgrade-sole/subscription-banner-upgrade-sole.component';
import { SubscriptionBannerTrialComponent } from './subscription-banner-trial/subscription-banner-trial.component';
import { SubscriptionBannerUpgradeWorkComponent } from './subscription-banner-upgrade/subscription-banner-upgrade-work/subscription-banner-upgrade-work.component';
import { SubscriptionBannerUpgradePropertyLimitComponent } from './subscription-banner-upgrade/subscription-banner-upgrade-property-limit/subscription-banner-upgrade-property-limit.component';
import { SubscriptionBannerPastDueComponent } from './subscription-banner-past-due/subscription-banner-past-due.component';
import { SubscriptionBannerUpgradeHoldingComponent } from './subscription-banner-upgrade/subscription-banner-upgrade-holding/subscription-banner-upgrade-holding.component';

/**
 * @TODO Alex TT-2543 refactor the whole module
 */
@NgModule({
  declarations: [
    SubscriptionBannerStartComponent,
    SubscriptionBannerFeedbackComponent,
    SubscriptionBannerUpgradeComponent,
    SubscriptionBannerUpgradePropertyComponent,
    SubscriptionBannerPaidComponent,
    SubscriptionBannerTrialSoleComponent,
    SubscriptionBannerTrialPropertyComponent,
    SubscriptionBannerUpgradeSoleComponent,
    SubscriptionBannerTrialComponent,
    SubscriptionBannerUpgradeWorkComponent,
    SubscriptionBannerUpgradePropertyLimitComponent,
    SubscriptionBannerPastDueComponent,
    SubscriptionBannerUpgradeHoldingComponent
  ],
  imports: [
    CommonModule,
    SubscriptionModule,
    MatDialogModule,
    DirectivesModule,
    SubscriptionBannerOnboardingModule
  ]
})
export class SubscriptionBannerModule {
}
