import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableExportComponent } from '@shared/component/export/data-table-export/data-table-export.component';
import { MaterialModule } from '@shared/material/material.module';
import { HtmlElementExportComponent } from '@shared/component/export/html-element-export/html-element-export.component';


@NgModule({
  declarations: [
    DataTableExportComponent,
    HtmlElementExportComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    DataTableExportComponent,
    HtmlElementExportComponent
  ]
})
export class ExportModule { }
