import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HoldingTypeMultiselectComponent } from './holding-type-multiselect.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    HoldingTypeMultiselectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectModule
  ],
  exports: [
    HoldingTypeMultiselectComponent
  ]
})
export class HoldingTypeMultiselectModule { }
