import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoleContactAutocompleteComponent } from './sole-contact-autocomplete.component';
import { MaterialModule } from '@shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@shared/directives/directives.module';
import { PreloaderModule, ValidationErrorModule } from '@shared/component';

@NgModule({
  declarations: [
    SoleContactAutocompleteComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    ValidationErrorModule,
    PreloaderModule
  ],
  exports: [
    SoleContactAutocompleteComponent
  ]
})
export class SoleContactSelectModule { }
