import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubCodeComponent } from '@shared/component/sub-code/sub-code.component';

@NgModule({
  declarations: [
    SubCodeComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SubCodeComponent
  ]
})
export class SubCodeModule { }
