import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { TranslateModule } from '@ngx-translate/core';
// components
import { SubscriptionBannerModule } from '@shared/component';
import { PipeModule } from '@shared/pipe/pipe.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { ComponentModule } from '@shared/component/component.module';
import { TermsModule } from '@shared/components/terms/terms.module';
import { RouterModule } from '@angular/router';
import { PrimeModule } from '@shared/prime/prime.module';
import { FormControlsModule } from '@shared/component/form-controls/form-controls.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SubscriptionBannerModule,
    PipeModule,
    DirectivesModule,
    ComponentModule,
    TermsModule,
    RouterModule,
    PrimeModule,
    FormControlsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    PipeModule,
    DirectivesModule,
    ComponentModule,
    TermsModule,
    RouterModule,
    PrimeModule,
    FormControlsModule
  ],
})
export class SharedModule {
}
