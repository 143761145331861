/**
 * Preloader view types.
 * type 'OVERLAY' type and placed in middle of screen.
 * type 'SECTION' puts preloader in section, that should be shown before content is loaded
 * type 'ICON' simple transparent spinner without labels and background
 */
export enum PreloaderTypeEnum {
  OVERLAY,
  SECTION,
  ICON,
  OVERLAY_NO_LABEL,
  SECTION_NO_LABEL
}
