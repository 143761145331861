import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transform camel case string into space-delimited string, each word that starts with a capital letter
 * 'camelCase' -> 'Camel Case'
 */
@Pipe({
  name: 'decamelize'
})
export class DecamelizePipe implements PipeTransform {
  transform(value: string, delimiter = ' '): string {
    if ((typeof value) !== 'string') {
      return value;
    }
    value = value.split(/(?=[A-Z])/).join(delimiter);
    value = value[0].toUpperCase() + value.slice(1);
    return value;
  }
}
