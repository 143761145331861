import { Pipe, PipeTransform } from '@angular/core';

/**
 * Wrap URL string with CSS url() string to apply image to container background image
 */
@Pipe({
  name: 'backgroundImageUrl'
})
export class BackgroundImageUrlPipe implements PipeTransform {
  transform(url: string): string {
    return `url("${url}")`;
  }
}
