import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YearChooserComponent } from './year-chooser.component';
import { MaterialModule } from '@shared/material/material.module';
import { FormsModule } from '@angular/forms';
import { PipeModule } from '@shared/pipe/pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [YearChooserComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    PipeModule,
    MatTooltipModule
  ],
  exports: [
    YearChooserComponent
  ],
})
export class YearChooserModule { }
