import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { User, UserService } from 'taxtank-core';
import { MatDialog } from '@angular/material/dialog';
import {
  TrialSubscriptionComponent
} from '@client/components/trial-subscription/trial-subscription.component';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class ClientGuard {
  constructor(private userService: UserService, private router: Router, private dialog: MatDialog) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // fetch because user can be changed while trying to access client area (post trial)
    return this.userService.fetch().pipe(map((users) => this.check(users.first)));
  }

  check(user: User): boolean {
    if (user.isEmployee()) {
      this.router.navigateByUrl('/firm');
    } else if (user.isOnboarding()) {
      this.dialog.open(TrialSubscriptionComponent, { disableClose: true, panelClass: ['mobile-full-screen'] });

      return false;
    }

    return true;
  }
}
