import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

/**
 * Custom date pipe
 */
@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
  constructor(private date: DatePipe) {}

  transform(value: string | Date, format = 'dd/MM/yyyy'): any {
    if (value) {
      const split = typeof value === 'string' ? value.split(' ')[0] : value;
      return this.date.transform(split, format);
    }
    return '';
  }
}
