import { NgModule } from '@angular/core';
import { PreloaderComponent } from '@shared/component/preloader/preloader.component';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProgressPreloaderComponent } from './progress-preloader/progress-preloader.component';
import { ProgressBarModule } from '@shared/component/progress-bar/progress-bar.module';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    ProgressBarModule
  ],
  declarations: [
    PreloaderComponent,
    ProgressPreloaderComponent
  ],
  exports: [
    PreloaderComponent,
    ProgressPreloaderComponent
  ],
})
export class PreloaderModule { }
