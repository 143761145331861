import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InnerCardComponent } from './inner-card.component';
import { InnerCardGroupComponent } from '@shared/component/inner-card/inner-card-group/inner-card-group.component';
import { PipeModule } from '@shared/pipe/pipe.module';

@NgModule({
  declarations: [
    InnerCardComponent,
    InnerCardGroupComponent
  ],
  imports: [
    CommonModule,
    PipeModule
  ],
  exports: [
    InnerCardComponent,
    InnerCardGroupComponent
  ]
})
export class InnerCardModule { }
