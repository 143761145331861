import { Pipe, PipeTransform } from '@angular/core';
import { AbstractModel } from 'taxtank-core';

/**
 * Pipe to call methods/getters in templates (to prevent memory leaks)
 * @Todo Apply pipe to all methods/getters called in templates
 */
@Pipe({
  name: 'call'
})
export class CallPipe implements PipeTransform {
  transform(model: object, methodName: string, ...args): any {
    if (!(methodName in model)) {
      throw new Error(`Method/getter '${methodName}' does not exist in class ${model.constructor.name}`);
    }

    if (model[methodName] instanceof Function) {
      return model[methodName](...args);
    }

    return model[methodName];
  }
}
