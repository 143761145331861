import { Component, Input } from '@angular/core';
import { AbstractControlComponent } from '@shared/component/form-controls/abstract-control/abstract-control.component';

/**
 * Customized form control component with checkbox functionality
 */
@Component({
  selector: 'app-toggler',
  templateUrl: './toggler.component.html',
  styleUrls: ['./toggler.component.scss'],
})
export class TogglerComponent extends AbstractControlComponent<boolean> {
  @Input() label: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() imageURL: string;

  onToggle(isSelected: boolean) {
    if (this.disabled) {
      return;
    }

    this.value = isSelected;
    this.onChange(this.value);
  }
}
