<!-- Default preloader -->
<div *ngIf="isOverlay" class="overlay">
  <div class="preloader-container">
    <mat-progress-spinner [diameter]="size || 40"
                          class="primary"
                          mode="indeterminate">
    </mat-progress-spinner>
    <span class="small" *ngIf="!hideLabel">{{label}}</span>
  </div>
</div>

<!-- Preloader for section -->
<section *ngIf="isSection">
  <div class="overlay">
    <div class="preloader-container">
      <mat-progress-spinner [diameter]="size || 40"
                            class="primary"
                            mode="indeterminate">
      </mat-progress-spinner>
      <span class="small" *ngIf="!hideLabel">{{label}}</span>
    </div>
  </div>
</section>

<!-- Icon preloader -->
<mat-progress-spinner *ngIf="isIcon" [diameter]="size || 30" mode="indeterminate"></mat-progress-spinner>
