import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressInputComponent } from './address-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DirectivesModule } from '@shared/directives/directives.module';
import { ValidationErrorModule } from '@shared/component';
import { CountryAutocompleteModule } from '@shared/component/form-controls/autocomplete/country-autocomplete/country-autocomplete.module';
import { PipeModule } from '@shared/pipe/pipe.module';

@NgModule({
  declarations: [
    AddressInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    DirectivesModule,
    ValidationErrorModule,
    CountryAutocompleteModule,
    PipeModule,
  ],
  exports: [
    AddressInputComponent
  ]
})
export class AddressInputModule { }
