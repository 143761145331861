import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_RIPPLE_GLOBAL_OPTIONS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MAT_EXPANSION_PANEL_DEFAULT_OPTIONS, MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { OverlayModule } from '@angular/cdk/overlay';
import { MATERIAL_CONFIG } from '@shared/material/material-config';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatTreeModule } from '@angular/material/tree';
import { MatChipsModule } from '@angular/material/chips';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  imports: [
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatExpansionModule,
    MatTabsModule,
    MatMenuModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatTableModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatTooltipModule,
    OverlayModule,
    MatSlideToggleModule,
    MatSortModule,
    MatPaginatorModule,
    MatStepperModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatSliderModule,
    MatBottomSheetModule,
    MatTreeModule,
    MatChipsModule
  ],
  exports: [
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatExpansionModule,
    MatTabsModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatButtonModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatTableModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatInputModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTooltipModule,
    OverlayModule,
    MatSlideToggleModule,
    MatSortModule,
    MatPaginatorModule,
    MatStepperModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatSliderModule,
    MatBottomSheetModule,
    MatTreeModule,
    MatChipsModule
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_CONFIG.dateFormat },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS, useValue: MATERIAL_CONFIG.expansionPanel },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: MATERIAL_CONFIG.label },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: MATERIAL_CONFIG.dialog },
    // { provide: MAT_LEGACY_DIALOG_DEFAULT_OPTIONS, useValue: MATERIAL_CONFIG.legacyDialog },
    { provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS, useValue: MATERIAL_CONFIG.progressSpinner },
    { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: MATERIAL_CONFIG.paginator },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: MATERIAL_CONFIG.ripple }
  ]
})
export class MaterialModule {
}
