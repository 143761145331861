<div (click)="onToggle(!value)" class="toggler" [class.checked]="value" [class.disabled]="disabled">
  <div class="toggler-content">
    <i class="{{icon}} is-big"></i>
    <img [src]="imageURL"  alt=""/>
    <ng-content></ng-content>
  </div>

  <div class="toggler-label">{{label}}</div>
  <div class="toggler-description">{{description}}</div>
</div>
