import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoleInvoiceTermSelectComponent } from './sole-invoice-term-select.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { ValidationErrorModule } from '@shared/component';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DirectivesModule } from '@shared/directives/directives.module';



@NgModule({
  declarations: [
    SoleInvoiceTermSelectComponent
  ],
  exports: [
    SoleInvoiceTermSelectComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    ValidationErrorModule,
    FormsModule,
    MatAutocompleteModule,
    DirectivesModule
  ]
})
export class SoleInvoiceTermSelectModule { }
