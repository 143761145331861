import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartAccountsAutocompleteComponent } from './chart-accounts-autocomplete.component';
import { PreloaderModule } from '@shared/component/preloader/preloader.module';
import { ValidationErrorModule } from '@shared/component/validation-error/validation-error.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/material/material.module';
import { DirectivesModule } from '@shared/directives/directives.module';

@NgModule({
  declarations: [
    ChartAccountsAutocompleteComponent
  ],
  imports: [
    CommonModule,
    ValidationErrorModule,
    ReactiveFormsModule,
    PreloaderModule,
    MaterialModule,
    FormsModule,
    DirectivesModule
  ],
  exports: [
    ChartAccountsAutocompleteComponent
  ]
})
export class ChartAccountsAutocompleteModule {
}
