import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'financialYear'
})
export class FinancialYearPipe implements PipeTransform {
  transform(year: number, format: string): any {
    if (year) {
      const { length } = year.toString();
      const financialFormat = year
        .toString()
        .substr(length - 2, length - 1);
      if (format === 'long') {
        const prevYear = +financialFormat - 1;
        return `${prevYear}’ - ${financialFormat}’`;
      }
      return financialFormat;
    }
    return '-';
  }
}
