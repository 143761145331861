import { Component, OnInit, ViewChild } from '@angular/core';
import { Toast, ToastService, ToastTypeEnum } from 'taxtank-core';
import { Message, MessageService as PrimeToastService } from 'primeng/api';
import { Toast as PrimeToast } from 'primeng/toast';
import isEqual from 'lodash/isEqual';
import remove from 'lodash/remove';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  @ViewChild(PrimeToast, { static: true }) toast: PrimeToast;

  constructor(
    private toastService: ToastService,
    private primeToastService: PrimeToastService
  ) {
  }

  ngOnInit(): void {
    this.toastService.get().subscribe((toast: Toast) => {
      this.primeToastService.add({
        severity: this.getSeverity(toast),
        summary: toast.title,
        detail: toast.message,
        life: toast.duration,
        sticky: !toast.autoClose,
        closable: false,
        data: {
          date: toast.date,
          onCloseClick: toast.onCloseClick,
          onCTAClick: toast.onCTAClick
        }
      });
    });
  }


  /**
   * toasts may have additional logic for manual closing (auto read notification for example).
   */
  onClose(message: Message): void {
    if(message.data.onCloseClick) {
      message.data.onCloseClick();
    }
    remove(this.toast.messages, (existingMessage: Message) => isEqual(existingMessage, message));
  }

  /**
   * map toast type to prime severity
   */
  private getSeverity(toast: Toast): string {
    switch (toast.type) {
      case ToastTypeEnum.INFO:
        return 'info';
      case ToastTypeEnum.SUCCESS:
        return 'success';
      case ToastTypeEnum.WARNING:
        return 'warn';
      case ToastTypeEnum.ERROR:
        return 'error';
    }
  }
}
