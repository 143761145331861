import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationPopupComponent } from './notification-popup/notification-popup.component';
import { RouterModule } from '@angular/router';
import { PipeModule } from '@shared/pipe/pipe.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { NotificationMessageComponent } from './components/notification-message/notification-message.component';
import { ImageModule } from '@shared/component/image/image.module';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    NotificationPopupComponent,
    NotificationsListComponent,
    NotificationMessageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipeModule,
    DirectivesModule,
    ImageModule,
    MatTooltipModule
  ],
  exports: [
    NotificationPopupComponent,
    NotificationMessageComponent,
    NotificationsListComponent
  ]
})
export class NotificationModule {
}
