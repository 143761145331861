import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordInputComponent } from '@shared/component/form-controls/input/password-input/password-input.component';
import { ValidationErrorModule } from '@shared/component';
import { ReactiveFormsModule } from "@angular/forms";
import { PasswordModule } from 'primeng/password';


@NgModule({
  declarations: [
    PasswordInputComponent
  ],
  imports: [
    CommonModule,
    ValidationErrorModule,
    ReactiveFormsModule,
    PasswordModule,
  ],
  exports: [
    PasswordInputComponent,
  ]
})
export class PasswordInputModule { }
