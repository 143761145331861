import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TankIndicatorsComponent } from './tank-indicators.component';
import { MaterialModule } from '@shared/material/material.module';

@NgModule({
  declarations: [
    TankIndicatorsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    TankIndicatorsComponent
  ]
})
export class TankIndicatorsModule {
}
