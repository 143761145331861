import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopyToClipboardComponent } from '@shared/component/copy-to-clipboard/copy-to-clipboard.component';
import { MaterialModule } from '@shared/material/material.module';
import { DirectivesModule } from '@shared/directives/directives.module';

@NgModule({
  declarations: [
    CopyToClipboardComponent
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    MaterialModule
  ],
  exports: [
    CopyToClipboardComponent
  ]
})
export class CopyToClipboardModule { }
