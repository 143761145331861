import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehicleClaimWorkUsageSliderComponent } from '@shared/component/form-controls/slider/vehicle-claim-work-usage-slider/vehicle-claim-work-usage-slider.component';
import { MaterialModule } from '@shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    VehicleClaimWorkUsageSliderComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    VehicleClaimWorkUsageSliderComponent
  ]
})
export class VehicleClaimWorkUsageSliderModule { }
