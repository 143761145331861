import { Component, Input, OnInit } from '@angular/core';
import { PreloaderTypeEnum } from '@shared/component/preloader/preloader-type.enum';

/**
 * @TODO mikhail refactoring, comments
 * Preloader element. By default it has overlay
 */
@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {
  @Input() type: PreloaderTypeEnum = PreloaderTypeEnum.OVERLAY;
  @Input() size: number;
  @Input() label = 'Loading...';
  hideLabel: boolean;
  // preloader types
  isOverlay: boolean;
  isSection: boolean;
  isIcon: boolean;
  preloaderTypes = PreloaderTypeEnum;

  ngOnInit(): void {
    this.isOverlay = this.type === PreloaderTypeEnum.OVERLAY || this.type === PreloaderTypeEnum.OVERLAY_NO_LABEL;
    this.isSection = this.type === PreloaderTypeEnum.SECTION || this.type === PreloaderTypeEnum.SECTION_NO_LABEL;
    this.isIcon = this.type === PreloaderTypeEnum.ICON;
    this.hideLabel = this.type === PreloaderTypeEnum.OVERLAY_NO_LABEL || this.type === PreloaderTypeEnum.SECTION_NO_LABEL;
  }
}
