import { environment } from '@env/environment';

/**
 * Run facebook initialization
 */
export function facebookInit() {
  // Do nothing when intercom app id is missing
  if (!environment['facebookAppId']) {
    return;
  }

  // FB init external script
  const tag = document.createElement('script');
  tag.src = 'https://connect.facebook.net/en_US/all.js#xfbml=1&appId=' + environment['facebookAppId'];
  tag.defer = true;
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  (window as any).fbAsyncInit = () => {
    // FB JavaScript SDK configuration and setup
    FB.init({
      appId      : 'InsertYourFacebookAppId', // FB App ID
      status     : true,
      cookie     : true,  // enable cookies to allow the server to access the session
      xfbml      : true,  // parse social plugins on this page
      version    : 'v14.0' // use graph api version 2.7
    });
  };

  // Load the JavaScript SDK asynchronously
  (((d, s, id) => {
    let js  = d.getElementsByTagName(s)[0];
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js['src'] = 'https:////connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  }) (document, 'script', 'facebook-jssdk'));
}
