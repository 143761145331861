import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe display placeholder string if value does not exist
 */
@Pipe({
  name: 'placeholder'
})
export class PlaceholderPipe implements PipeTransform {
  transform(value: any, placeholder = '-'): any {
    return value || placeholder;
  }

}
