<p-toast #toast>
  <ng-template let-message pTemplate="message">
    <span class="p-toast-message-icon" *ngIf="!message.icon">
      <i *ngIf="message.severity === 'success'" class="icon-check ic-success"></i>
      <i *ngIf="message.severity === 'info'" class="icon-info ic-primary d-block"></i>
      <i *ngIf="message.severity === 'error'" class="icon-close ic-error d-block"></i>
      <i *ngIf="message.severity === 'warn'" class="icon-warning ic-warning d-block"></i>
    </span>
    <div class="p-toast-message-text">
      <div class="p-toast-summary">{{ message.summary }}</div>
      <div class="p-toast-detail">{{ message.detail }}</div>
      <!-- @Todo Implement CTA button logic (TT-2239)-->
      <!--<button *ngIf="message.data.onCTAClick" class="primary compact p-5 mt-10 h-auto">-->
      <!--  See details-->
      <!--</button>-->
    </div>
    <button class="icon-close ic-text"
            (click)="onClose(message)">
    </button>
  </ng-template>
</p-toast>
